export function useCheckSlotsAreEmpty(slots: any) {
  function isSlotAvailable() {
    return !!slots?.footer;
  }

  function hasSlotContent(slot: any, props = {}) {
    return !isSlotEmpty(slot, props);
  }

  function isSlotEmpty(slot: any, props = {}) {
    return isVNodeEmpty(slot?.(props));
  }

  function isVNodeEmpty(vnode: any) {
    return !vnode || asArray(vnode).every(vnode => vnode.type === 'Comment');
  }

  function asArray(arg: any) {
    return Array.isArray(arg) ? arg : arg != null ? [arg] : [];
  }

  return {
    hasSlotContent,
    isSlotAvailable,
    isSlotEmpty,
    isVNodeEmpty,
  };
}
