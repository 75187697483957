<template>
  <div class="flex items-center max-w-full">
    <div
      class="flex-auto w-full flex gap-8 overflow-x-auto overflow-y-visible py-[2px] flex-col-reverse lg:flex-row-reverse justify-between"
      v-bind="$attrs"
    >
      <slot name="actions" />
      <div class="flex items-center gap-2 border-b border-neutral-200 flex-auto">
        <div
          v-for="item in items"
          :key="item.value"
          class="flex items-center justify-center pb-1 relative font-medium cursor-pointer hover:text-primary-700 nav-item group"
          :class="{
            'active top-[2px] border-b-[3px] border-primary-700': isActive(item.value),
            'text-typography-disabled': !isActive(item.value),
          }"
          @click="changeTab(item)"
        >
          <div
            class="flex items-center gap-2 p-2 rounded-md nav-item-text hover:bg-neutral-200 text-xsm"
            :class="{
              'text-primary-700': isActive(item.value),
            }"
            :data-testid="`tab-${item.value}`"
          >
            <slot :name="`tab-${item.value}`" :item="item" :active="isActive(item.value)">
              {{ item.label }}
            </slot>
            <div
              v-if="hasSlotContent(slots[`item-${item.value}`])"
              class="nav-item-num text-xsm rounded-md items-center justify-center py-[2px] px-[6px] flex"
              :class="{
                'text-primary-700 bg-primary-100 group-hover:bg-neutral-300': isActive(item.value),
                'bg-neutral-200 hover:text-primary-700 group-hover:bg-neutral-300': !isActive(item.value),
              }"
            >
              <slot :name="`item-${item.value}`" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ref="content" style="overflow: auto" class="scrollbar-hidden pt-8 relative h-full" :class="{ hidden: loading }">
    <slot />
  </div>

  <div v-if="loading" class="flex items-center justify-center h-[300px]">
    <PulseLoadingSpinner />
  </div>
</template>

<script setup lang="ts">
import { useCheckSlotsAreEmpty } from '~/support/utils/is-slot-empty';

type NavItem = { label: string; value: string; to?: string };

const slots = useSlots();

const props = defineProps({
  active: {
    default: 'new',
    type: String,
  },
  items: {
    default: () => [],
    required: true,
    type: Array as PropType<NavItem[]>,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  noHistory: {
    default: false,
    type: Boolean,
  },
});

const { hasSlotContent } = useCheckSlotsAreEmpty(slots);

const emit = defineEmits(['nav:click']);

const currentTab = computed(() => {
  return props.active;
});

const isActive = (value: string) => {
  return currentTab.value === value;
};

const content = ref<HTMLElement | null>(null);

// each time we use navigation it should use the URL to determine the active tab
function changeTab(item: NavItem) {
  content.value && (content.value.scrollTop = 0);

  if (item.to) {
    if (props.noHistory) {
      useRouter().replace(item.to);
    } else {
      useRouter().push(item.to);
    }
  }

  emit('nav:click', item.value);
}

function handleResize() {
  if (!content.value) return;
  content.value.style.height = `${window.innerHeight - 170}px`;
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
